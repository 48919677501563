/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

const React = require("react")
// You can delete this file if you're not using it
require("./src/styles/global.css")
require('./src/styles/fonts.css')
const { ThemeProvider } = require("./src/context/Theme/ThemeProvider")
const { ThemeToggler } = require("gatsby-plugin-dark-mode")

exports.wrapRootElement = ({ element }) => {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => {
        const [t, setT] = React.useState(theme)

        const toggle = React.useCallback(() => {
          const next = t === "light" ? "dark" : "light"
          toggleTheme(next)
          setT(next)
        }, [toggleTheme, t])

        return (
          <ThemeProvider theme={t} toggleTheme={toggle}>
            {element}
          </ThemeProvider>
        )
      }}
    </ThemeToggler>
  )
}
