import React from "react"
import ThemeContext from "./ThemeContext"

export const ThemeProvider = ({ theme, toggleTheme, children }) => {
  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
